import "./App.css";
import Bio from "./components/Bio";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import LazyLoad from "react-lazy-load";

function App() {
	return (
		<section className="App">
			<section className="wrapper">
				<LazyLoad>
					<Bio></Bio>
				</LazyLoad>
				<LazyLoad>
					<Projects></Projects>
				</LazyLoad>
				<LazyLoad>
					<Contact></Contact>
				</LazyLoad>
			</section>
		</section>
	);
}

export default App;

function Projects() {
	return (
		<section id="projects">
			<ul>
				<li id="bumble">
					<h3>Bumble Inc.</h3>
					<a
						href="https://ir.bumble.com/overview/default.aspx"
						target="_blank"
						rel="noreferrer"
					>
						<span className="sr-only">
							Visit website (opens in new window)
						</span>
					</a>
				</li>

				<li id="fbb">
					<h3>First Business Bank</h3>
					<a
						href="https://ir.firstbusiness.bank/investor-relations/default.aspx"
						target="_blank"
						rel="noreferrer"
					>
						<span className="sr-only">
							Visit website (opens in new window)
						</span>
					</a>
				</li>

				<li id="lrc">
					<h3>Lithium Royalty Corp.</h3>
					<a
						href="https://www.lithiumroyaltycorp.com/home/default.aspx"
						target="_blank"
						rel="noreferrer"
					>
						<span className="sr-only">
							Visit website (opens in new window)
						</span>
					</a>
				</li>

				<li id="macys">
					<h3>Macy's Inc.</h3>
					<a
						href="https://macysinc.com/overview/default.aspx"
						target="_blank"
						rel="noreferrer"
					>
						<span className="sr-only">
							Visit website (opens in new window)
						</span>
					</a>
				</li>

				<li id="mativ">
					<h3>Mativ Holdings</h3>
					<a
						href="https://ir.mativ.com/overview/default.aspx"
						target="_blank"
						rel="noreferrer"
					>
						<span className="sr-only">
							Visit website (opens in new window)
						</span>
					</a>
				</li>
			</ul>
		</section>
	);
}

export default Projects;

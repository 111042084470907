function Bio() {
	return (
		<section id="bio">
			<h1>Josh Carson</h1>
			<h2>Front End Web Developer</h2>
		</section>
	);
}

export default Bio;

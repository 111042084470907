import linkedin from "../icons/linkedin.png";

function Contact() {
	return (
		<section id="contact">
			<article>
				<p>Toronto, ON</p>

				<a
					href="https://www.linkedin.com/in/joshcarsondev/"
					target="_blank"
					rel="noreferrer"
				>
					<img src={linkedin} alt="" aria-hidden="true" />
					<span className="sr-only">
						Visit Josh's LinkedIn Profile (opens in new window)
					</span>
				</a>
			</article>
		</section>
	);
}

export default Contact;
